// tslint:disable:variable-name
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, retryWhen, tap } from 'rxjs/operators';
import { PaginatorState } from '../models/paginator.model';
import { ITableState, TableResponseModel } from '../models/table.model';
import { BaseModel, PlaceDetailModel, PlaceModel } from '../models/base.model';
import { SortState } from '../models/sort.model';
import { GroupingState } from '../models/grouping.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from 'src/app/modules/auth/_models/auth.model';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

export abstract class TableService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  placesKey = "AIzaSyAulFuMHfUWVXe7UN41FOaIXCzfDSwU18U";

  // Getters
  get items$() {
    return this._items$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }
  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  protected http: HttpClient;
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`;
  constructor(http: HttpClient) {
    this.http = http;
  }
  // ************************** Maintenance ****************
  checkMaintenance() {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/read.php';
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // CREATE
  // server should return the object with ID
  create(item: BaseModel): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/create.php';
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<BaseModel>(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  findExact(str: string): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/find-exact.php';
    this._errorMessage.next('');
    let body = {
      "searchTerm": str,
      "user_id": ''
    }
    return this.http.post<BaseModel>(url, body, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND EXACT ITEMS', err);
        return of({ id: undefined });
      })
    );
  }

  /* find item by name */
  findName(str: string): Observable<BaseModel> {
    const url = this.API_URL + '/find-name.php';
    this._errorMessage.next('');
    return this.http.post<BaseModel>(url, { "searchTerm": str }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND NAME ITEMS', err);
        return of({ id: undefined });
      })
    );
  }

  // READ (Returning filtered list of entities)
  find(tableState: ITableState, customer_id: number): Observable<TableResponseModel<T>> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    let urlparam = "";
    if (customer_id != 0) {
      urlparam = '?customer_id=' + customer_id;
    }
    const url = this.API_URL + '/find.php' + urlparam;
    this._errorMessage.next('');
    return this.http.post<TableResponseModel<T>>(url, tableState, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({ items: [], total: 0 });
      })
    );
  }
  validateUserForPackege(data: any) {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');

    const url = `${this.API_URL}/validate.php`;

    console.log(data, "packegeId============", url);

    return this.http.post<BaseModel>(url, data, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY ID', data, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  viewUserOrders(data: any): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/readuserorder.php`;
    return this.http.post<BaseModel>(url,data, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY ID', data, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getItemById(id: number): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/read.php?id=${id}`;
    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY ID', id, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getAllItems(): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/read_all.php`;
    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ALL ITEMS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getAllItemsId(id: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/read_all_id.php?id=${id}`;
    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ALL ITEMS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getUserByToken(jwt: string): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const formData: FormData = new FormData();
    formData.append('jwt', jwt);
    const url = `${this.API_URL}/get_token.php`;
    return this.http.post<BaseModel>(url, formData, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY ID', jwt, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getChildren(id: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/read_children.php?id=${id}`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET CHILDREN', err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  matchedChildren(): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/match.php`;
    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ALL ITEMS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }


  getDetails(id: number): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/get_details.php?id=${id}`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET DETAILS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getPhotosCustomer(customer_id: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/get_photos_customer.php?id=${customer_id}`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET PHOTOS CUSTOMER', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getPhotosClass(school_id: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/get_photos_class.php?id=${school_id}`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET PHOTOS CLASS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getPlaces(input: string): Observable<PlaceModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?components=country:au&input=${input}&key=${this.placesKey}`;
    console.log(url,'url')
    return this.http.get<PlaceModel>(url).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET PLACES', err);
        return of({ predictions: {}, status: 'Failed' });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  getPlaceDetails(place_id: string): Observable<PlaceDetailModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `https://maps.googleapis.com/maps/api/place/details/json?placeid=${place_id}&key=${this.placesKey}`;
    console.log(url,'url')

    return this.http.get<PlaceDetailModel>(url).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET PLACE DETAILS', err);
        return of({ result: null, status: 'Failed' });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(item: BaseModel): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = `${this.API_URL}/update.php?id=${item.id}`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', item, err);
        return of(item);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = { ids, status };
    const url = this.API_URL + '/update_status.php';
    return this.http.put(url, body, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  claim(item: BaseModel): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = `${this.API_URL}/claim.php`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('CLAIM', item, err);
        return of(item);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  edit(item: any): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = `${this.API_URL}/edit.php`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('update', item, err);
        return of(item);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  deleteClaim(id: any): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/delete_claim.php?id=${id}`;
    return this.http.delete(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  delete(id: any): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/delete.php?id=${id}`;
    return this.http.delete(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL + '/delete_items.php';
    const body = { ids };
    return this.http.put(url, body, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  public fetch(customer_id: number = 0) {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.find(this._tableState$.value, customer_id)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.items);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.total
            ),
          });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          console.log("FETCH", err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  upload(product_id: number, file: File): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const formData: FormData = new FormData();
    formData.append('product_id', product_id.toString());
    formData.append('file', file);
    const req = this.http.post(`${this.API_URL}/upload.php`, formData, {
      reportProgress: true,
      responseType: 'json',
      observe: 'events',
      headers: httpHeaders
    }).pipe(
      map((event) => {
        return event;
      })
    );
    return req;
  }

  getFiles(productId: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    return this.http.get(`${this.API_URL}/files.php?pid=${productId}`, { headers: httpHeaders });
  }

  deleteImage(id: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/delete_image.php?id=${id}`;
    return this.http.delete(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE IMAGE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  upload_photo(file: File, school_id: string, upload_type: string): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('school_id', school_id);
    formData.append('upload_type', upload_type);
    // const req = new HttpRequest('POST', `${this.API_URL}/upload.php`, formData, {
    const req = this.http.post(`${this.API_URL}/upload.php`, formData, {
      reportProgress: true,
      responseType: 'json',
      observe: 'events',
      headers: httpHeaders
      // });
    }).pipe(
      map((event) => {
        return event;
      })
    );
    return req;
  }

  //Prepay

  getPrepayStatus(): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/prepay_available.php`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET PREPAY STATUS', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getAllPrepay(): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/read_all.php`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET PREPAY LIST', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getPrepayList(): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/customer_pack_list.php`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET PREPAY LIST', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  getChildList(): Observable<BaseModel> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/child_list.php`;

    return this.http.get<BaseModel>(url, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET CHILD LIST', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  upgradePrepayOrder(item): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/upgrade_order.php';
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<BaseModel>(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('PREPAY ORDER', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  prepayOrder(item): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/order.php';
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<BaseModel>(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('PREPAY ORDER', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  orderPackeg(item): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    const url = this.API_URL + '/order.php';
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<BaseModel>(url, item, { headers: httpHeaders }).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('PREPAY ORDER', err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }
  export_prepays(school_id: number): Observable<any> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.authToken) {
      return of(undefined);
    }
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    return this.http.get(`${this.API_URL}/export_prepays.php?sid=${school_id}`, { headers: httpHeaders });
  }



  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} });
    this.patchStateWithoutFetch({ sorting: new SortState() });
    this.patchStateWithoutFetch({ grouping: new GroupingState() });
    this.patchStateWithoutFetch({ searchTerm: '' });
    this.patchStateWithoutFetch({
      paginator: new PaginatorState()
    });
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>, customer_id: number = 0) {
    this.patchStateWithoutFetch(patch);
    this.fetch(customer_id);
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }
}
