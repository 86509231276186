import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd, NavigationError } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { environment } from 'src/environments/environment';
import { ChildrenService } from './modules/children/_services';
import { HttpClient } from '@angular/common/http';
import { E } from '@angular/cdk/keycodes';
import { MaintenanceService } from './modules/e-commerce/_services/maintenance.service';
MaintenanceService


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  ipAddress: any;

  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    public childrenService: ChildrenService,
    public _cdr: ChangeDetectorRef,
    private http: HttpClient,
    private maintenanceService: MaintenanceService
  ) {
    // register translations
    this.getIp()
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang
    );
  }
  maintenance: boolean = false

  ngOnInit() {

    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);

    this.addGoogleMapScript();
  }
  getIp() {

    this.maintenanceService.checkMaintenance().subscribe((rec) => {
      if (rec && rec.records[0].is_on_maintenance == 1) {
        this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
          this.ipAddress = res.ip;
          this.maintenance = true
          let allowdIP = rec.records[0].ip.trim().split(',')
          if (allowdIP.includes(this.ipAddress)) {
            this.maintenance = false
          }
          this._cdr.detectChanges();

        })
      };
    })
    // if(this.ipAddress){
    //   this.maintenance= false
    // }else{
    //   this.maintenance= true

    // }

    // if
  }

  addGoogleMapScript() {
    var scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = 'https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=' + environment.googleApi;

    scriptElement.onload = () => { console.log("Google Script Ready"); }
    document.head.appendChild(scriptElement);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
