
<section>
    <div class="holder">
        <img src="./assets/media/logos/logo-happy-square.png" style="width:250px;" />

        <h1><span class="tbl">Website is currently under maintenance.</span></h1>
        <p><span class="tbl">We'll be back shortly.</span></p>
        
        <br>
        <br />
        </div>
</section>