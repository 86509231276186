import { Inject,Injectable } from '@angular/core';
import { TableService } from '../../../_metronic/shared/crud-table';
import { environment } from '../../../../environments/environment';
import { Discount } from '../_models/discount.model';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService extends TableService<Discount> {
  API_URL = `${environment.apiUrl}/maintenance`;
  constructor(@Inject(HttpClient) http) {
    super(http);
  }

  
}
