import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from '../modules/e-commerce/_services/maintenance.service';

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.scss']
})
export class MaintenancePageComponent implements OnInit {
  ipAddress: any;

  constructor(private http:HttpClient) { }

  ngOnInit(): void {

  }
  
}
