import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import { map } from 'rxjs/operators';

const API_BASE_URL = `${environment.apiUrl}`;
const API_USERS_URL = `${environment.apiUrl}/auth`;
const API_USERS_URL_order = `${environment.apiUrl}/order`;


@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  /* login user */
  login(email: string, password: string, code: string): Observable<any> {
    let res = this.http.post<AuthModel>(`${API_USERS_URL}/client/login.php`, { 
      "email": email, "password": password, "code": code
    });
    res.subscribe(data => {
      // console.log("login data", data)
    });
    return res;
  }
apiurl(){
  return API_BASE_URL;
}
  createUser(user: UserModel): Observable<any> {
    return this.http.post(`${API_USERS_URL}/client/register.php`, user)
      // .pipe(map(data => { console.log("all data3", data); }));
  }

  /* check if email address exists */
  checkEmail (email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/check-email.php`, {"email": email} );
  }

  /* change password with old password */
  changePassword(email: string, oldPsw: string, newPsw: string): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/client/change_psw.php`, {
      "email": email, 
      "oldPsw": oldPsw, 
      "newPsw": newPsw 
    });
  }

  /* change password with hash */
  forgotChangePassword(hash: string, psw: string): Observable<any> {
    console.log("forgotchangepassword vals", hash, psw);
    return this.http.post<any>(`${API_USERS_URL}/client/forgot_psw.php`, {
      "hash": hash, 
      "psw": psw
    });
  }

  /* query reset api */
  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>(`${API_USERS_URL}/client/reset_psw.php`, {"email": email});
  }

  getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/client/me.php`, {
      headers: httpHeaders,
    });
  }
  getAllOrdes(id:any){
    return this.http.get(`${API_USERS_URL_order}/readuserorder.php?user_id=${id}`, {observe:'response'});
  }
  getorderById(id:any,auth):Observable<any>{
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${auth.authToken}`,
    });
    return this.http.get(`${API_USERS_URL_order}/get_details.php?id=${id}`, {headers:httpHeaders});
  }

  ping(): Observable<AuthModel> {
    return this.http.get<AuthModel>(`${API_BASE_URL}/ping.php`);
  }

  loginByAdmin(email: string, token): Observable<any> {
    var body = {
      "email":email
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    let options = { headers: headers };
    return this.http.post<UserModel>(`${API_USERS_URL}/login-by-email.php`
      , body, options
    );
  }
}
